import { SiteLocation } from '@/shared/types/sites';
import {
  getRandomNumber,
  getRandomReference,
} from '@vaibe-github-enterprise/kd-kse-fe-commons';

export const site = {
  name: 'Barcelona \uD83D\uDC83\uD83C\uDFFE',
  reference: '64cf2bc9-78d4-4da4-a2de-c7230cada187',
  city: 'Barcelona',
  country: 'Spain',
  state: 'Catalonia',
  timezone: {
    utcOffset: 'UTC+02:00',
    ianaTimezone: 'Europe/Madrid',
  },
};

export function mockSiteLocation(): SiteLocation {
  return {
    city: getRandomReference(),
    state: getRandomReference(),
    country: getRandomReference(),
    latitude: getRandomNumber(10),
    longitude: getRandomNumber(10),
  };
}

export function mockSiteLocationList(length: number): SiteLocation[] {
  return Array.from({ length }, () => mockSiteLocation());
}
