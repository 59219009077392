import getNameInitials from '../helpers/getNameInitials/getNameInitials';
import { AdminProfile } from '../types/profile';

interface Profile extends AdminProfile {}

class Profile {
  constructor(adminProfile: AdminProfile) {
    Object.assign(this, adminProfile);
  }

  getNameInitials() {
    return getNameInitials(this.user.name);
  }
}

export default Profile;
