import rolesService from '@/shared/services/rolesService/rolesService';
import { GetDefaultAndCustomRolesResponse } from '@/shared/types/roles';
import { RoleTypeStaticReference } from '@vaibe-github-enterprise/kd-kse-fe-commons';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export default defineStore('roles', () => {
  const activePermissionCodes = ref<Record<string, boolean>>({});
  const roleLevelName = ref<string>('');

  function $reset(): void {
    activePermissionCodes.value = {};

    roleLevelName.value = '';
  }

  function getDefaultAndCustomRoles(
    roleTypeStaticReference: RoleTypeStaticReference,
    organizationName?: string,
  ): Promise<GetDefaultAndCustomRolesResponse> {
    return rolesService.getDefaultAndCustomRoles(
      roleTypeStaticReference,
      organizationName,
    );
  }

  async function loadProfileRoleLevel(): Promise<void> {
    try {
      const response = await rolesService.getProfileRoleLevels();

      response.activePermissionsCodes.forEach((permissionCode: string) => {
        activePermissionCodes.value[permissionCode] = true;
      });

      roleLevelName.value = response.name;
    } catch {
      activePermissionCodes.value = {};

      roleLevelName.value = '';
    }
  }

  function isPermissionActive(permissionCode: string): boolean {
    return activePermissionCodes.value[permissionCode] ?? false;
  }

  return {
    getDefaultAndCustomRoles,
    loadProfileRoleLevel,
    isPermissionActive,
    roleLevelName,
    $reset,
  };
});
