import { createApp } from 'vue';
import { createPinia } from 'pinia';
import i18n from '@/plugins/i18n';
import Quasar from 'quasar/src/vue-plugin.js';;
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import quasarUserOptions from './quasar-user-options';
import App from './App.vue';
import router from './router';
import worker from './mocks/browser';
import '@/styles/app.scss';

if (process.env.VUE_APP_USE_MOCK_SERVER === 'true') {
  worker.start({
    onUnhandledRequest: 'bypass',
  });
}

const app = createApp(App);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
app.use(i18n).use(Quasar, quasarUserOptions).use(pinia).use(router);

router.isReady().then(() => {
  // Waiting for the router to be ready prevents race conditions when returning from a loginRedirect or acquireTokenRedirect
  app.mount('#app');
});
