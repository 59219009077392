/* istanbul ignore file */
import { I18nOptions, createI18n } from 'vue-i18n';
import enUS from '@/locales/en_US/en_US.json';
import ptPT from '@/locales/pt_PT/pt_PT.json';
import commonUS from '@/locales/en_US/common_en_US.json';
import commonPT from '@/locales/pt_PT/common_pt_PT.json';
import { Locale } from '@/shared/types/generic';

const messages = {
  'en-US': { ...enUS, ...commonUS },
  'pt-PT': { ...ptPT, ...commonPT },
} as I18nOptions['messages'];

export default createI18n({
  legacy: false,
  locale: process.env.VUE_APP_I18N_LOCALE || Locale.US,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || Locale.US,
  globalInjection: true,
  globalInstall: true,
  messages,
});
