import i18n from '@/plugins/i18n';
import { RouteRecordName } from 'vue-router';

export default function useDynamicTitle() {
  function updateTitle(routeName?: RouteRecordName | null | undefined) {
    if (routeName) {
      const title = i18n.global.t(`nav.${String(routeName)}`);

      if (!title.includes('nav.')) {
        const projectTitle = i18n.global.t('nav.admin');

        document.title = `${projectTitle} - ${title}`;
      } else {
        document.title = 'Vaibe Admin';
      }
    }
  }

  return {
    updateTitle,
  };
}
