import { AdminProfile } from '@/shared/types/profile';
import { getRandomReference } from '@vaibe-github-enterprise/kd-kse-fe-commons';

const profile: AdminProfile = {
  user: {
    reference: 'test-reference',
    changePassword: false,
    email: 'hannahuser@tribehero.com',
    name: 'Hannah Arendt',
    organizationReference: getRandomReference(),
  },
  userConfig: {
    language: 'en-US',
  },
};

export default profile;
