import {
  ResponseMetadata,
  StatusStaticReference,
} from '@/shared/types/generic';
import { Admin } from '@/shared/types/admins';

export const createAdmin = (
  adminName: string,
  adminEmail: string,
  reference: string,
  adminStatus: StatusStaticReference,
): Admin => {
  return {
    name: adminName,
    email: adminEmail,
    status: adminStatus,
    reference,
  };
};

export const admins = [
  createAdmin(
    'Admin1',
    'admin1@gmail.com',
    'admin-reference-1',
    StatusStaticReference.ACTIVE,
  ),
  createAdmin(
    'Admin2',
    'admin2@gmail.com',
    'admin-reference-2',
    StatusStaticReference.INVITED,
  ),
  createAdmin(
    'Admin3',
    'admin3@gmail.com',
    'admin-reference-3',
    StatusStaticReference.INACTIVE,
  ),
  createAdmin(
    'Admin4',
    'admin4@gmail.com',
    'admin-reference-4',
    StatusStaticReference.DELETED,
  ),
];

export const metadata: ResponseMetadata = {
  totalItems: admins.length,
  lastUpdated: 1595030400000,
};

export const getAdmins = () => ({
  metadata,
  results: {
    admins,
  },
});
