import {
  GetDefaultAndCustomRolesResponse,
  GetProfileRoleLevelResponse,
} from '@/shared/types/roles';
import {
  getRandomReference,
  PermissionAction,
  PermissionType,
  Role,
} from '@vaibe-github-enterprise/kd-kse-fe-commons';

export const examplePermissionType: PermissionType = {
  name: 'Challenges',
  description: 'Permissions for challenges',
  staticReference: 'Challenges',
  active: true,
  permissions: [
    {
      name: 'Read',
      description: 'Read',
      code: 'ChallengesRead',
      permissionActionStaticReference: PermissionAction.READ,
      active: true,
    },
    {
      name: 'Edit',
      description: 'Edit',
      code: 'ChallengesEdit',
      permissionActionStaticReference: PermissionAction.EDIT,
      active: true,
    },
    {
      name: 'Duplicate',
      description: 'Duplicate',
      code: 'ChallengesDuplicate',
      permissionActionStaticReference: PermissionAction.DUPLICATE,
      active: true,
    },
    {
      name: 'Delete',
      description: 'Delete',
      code: 'ChallengesDelete',
      permissionActionStaticReference: PermissionAction.CREATE,
      active: false,
    },
  ],
};

export const exampleRole: Role = {
  name: 'role.manager',
  description:
    'Role for manager with a very very very very long description about what it does and it occupies multiples lines on this',
  reference: 'Manager',
  active: true,
  permissionTypes: [examplePermissionType] as PermissionType[],
};

const mockPermissionCodes: string[] = [
  'AD_001',
  'AD_002',
  'AD_003',
  'AD_004',
  'AD_005',
];

export function mockGetProfileRoleLevelResponse(): GetProfileRoleLevelResponse {
  return {
    name: getRandomReference(),
    activePermissionsCodes: mockPermissionCodes,
  };
}

export const mockRoles = (): GetDefaultAndCustomRolesResponse => {
  return {
    defaultRoles: [exampleRole],
    customRoles: [exampleRole],
  };
};
