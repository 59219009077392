<script setup lang="ts">
import { computed } from 'vue';
import Profile from '@/shared/classes/profile';
import AppDrawerLink from '@/components/app/AppDrawerLink/AppDrawerLink.vue';
import useProfileStore from '@/store/profile/useProfileStore';
import ProfileAvatar from '../ProfileAvatar/ProfileAvatar.vue';

interface Props {
  mini: boolean;
}

defineProps<Props>();

const profileStore = useProfileStore();

const profileInstance = computed(
  () => profileStore.userProfile && new Profile(profileStore.userProfile),
);
</script>

<template>
  <AppDrawerLink
    v-if="profileInstance"
    to="/account-settings"
    class="profile-drawer-link"
    :mini="mini"
    :label="profileInstance.user.name"
  >
    <template #leading>
      <ProfileAvatar
        size="24px"
        label-font-size="10px"
        background-color="white"
        :name-initials="profileInstance.getNameInitials()"
      />
    </template>
  </AppDrawerLink>
</template>
