import { CustomRouteLocationNormalized } from '@/shared/types/router';
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

export default function usePreventDirectRouteChange() {
  function preventDirectRouteChange(
    from: RouteLocationNormalized,
    to: CustomRouteLocationNormalized,
    next: NavigationGuardNext,
    destination = '/',
  ) {
    if (from.name || to.query.bypass === 'true') {
      next();
    } else {
      next(destination);
    }
  }

  return {
    preventDirectRouteChange,
  };
}
