export default function getNameInitials(name?: string): string {
  if (!name) {
    return '';
  }
  const [firstName, secondName] = name.split(' ');

  if (secondName) {
    return firstName[0].concat(secondName[0]);
  }

  return firstName[0];
}
