import axios from '@/plugins/http/axios';
import { RoleTypeStaticReference } from '@vaibe-github-enterprise/kd-kse-fe-commons';
import {
  GetProfileRoleLevelResponse,
  GetDefaultAndCustomRolesResponse,
} from '@/shared/types/roles';

export default {
  getProfileRoleLevels(): Promise<GetProfileRoleLevelResponse> {
    return axios.get('/profiles/role-levels-permissions');
  },

  getDefaultAndCustomRoles(
    roleTypeStaticReference: RoleTypeStaticReference,
    organizationReference?: string,
  ): Promise<GetDefaultAndCustomRolesResponse> {
    return axios.get('/role-levels', {
      params: {
        organization_reference: organizationReference,
        role_level_user_type_static_reference: roleTypeStaticReference,
      },
    });
  },
};
