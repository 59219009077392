import { defineStore } from 'pinia';
import { HttpStatus } from '@/shared/types/generic';

interface ErrorState {
  errorCode?: HttpStatus;
}

export default defineStore({
  id: 'error',

  state: (): ErrorState => ({
    errorCode: undefined,
  }),

  actions: {
    setErrorCode(errorCode?: HttpStatus) {
      this.errorCode = errorCode;
    },
  },
});
