/* eslint-disable camelcase */
export interface Base {
  reference: string;
}

export interface ResponseMetadata {
  totalItems?: number;
  lastUpdated?: number;
}

export interface ApiResponse<T> {
  metadata: ResponseMetadata;
  results: T;
}

export interface AppButtonToggleOption {
  label?: string;
  value: string | number;
  slot?: string;
}

export interface SkeletonStyle {
  type:
    | 'QAvatar'
    | 'QBadge'
    | 'QBtn'
    | 'QCheckbox'
    | 'QChip'
    | 'QInput'
    | 'QRadio'
    | 'QRange'
    | 'QSlider'
    | 'QToggle'
    | 'QToolbar'
    | 'circle'
    | 'rect'
    | 'text';
  style: string;
}

export type AppLabelSize = 'medium' | 'large';

export interface Range<T> {
  from: T;
  to: T;
}

export enum ImageType {
  COVER = 1,
  CUSTOM,
}

export interface Image {
  id: number;
  name: string;
  url: string;
  description: string;
  organizationId: number;
  imageTypeId: ImageType;
  default: boolean;
}

export enum Locale {
  PT = 'pt-PT',
  US = 'en-US',
}

export enum Currency {
  COIN = 'COIN',
  CUSTOM = 'CUSTOM',
}

export enum HttpStatus {
  UNAUTHORIZED_USER = 401,
  FORBIDDEN = 403,
  NO_CONNECTION = 502,
  SERVER_MAINTENANCE = 503,
  INACTIVE_USER = 700,
}

export enum RouteAuthenticationRequirement {
  AUTHENTICATED,
  UNAUTHENTICATED,
  UNIVERSAL,
}

export enum DatePeriod {
  ZERO = 0,
  SECOND = 1000,
  MINUTE = 60000,
  HOUR = 3600000,
  DAY = 86400000,
  WEEK = 604800000,
  MONTH = 2592000000,
}
export interface PaginationParams {
  order_by: string;
  query_by: string;
  sort_by: string;
  page?: number;
  size?: number;
}

export enum StatusStaticReference {
  ACTIVE = 'S_1',
  DELETED = 'S_2',
  SCHEDULED = 'S_3',
  LIVE = 'S_4',
  COMPLETED = 'S_5',
  NOT_STARTED = 'S_6',
  READY = 'S_7',
  ON_GOING = 'S_8',
  CALCULATING_RESULTS = 'S_9',
  DONE = 'S_10',
  NOT_CLAIMED = 'S_11',
  CLAIMED = 'S_12',
  INACTIVE = 'S_13',
  DELIVERY = 'S_14',
  NOT_DELIVERY = 'S_15',
  CANCELED = 'S_16',
  INVITED = 'S_17',
  DISABLED = 'S_18',
}
