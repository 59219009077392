export function isGreaterThan(comparator?: number) {
  return (payload?: number) => (payload || 0) > (comparator || 0);
}

export function isDateAfter(comparator: number) {
  return (payload: number) => new Date(payload) > new Date(comparator);
}

export function isDateAfterOrEqual(comparator: number) {
  return (payload: number) => new Date(payload) >= new Date(comparator);
}

export function isBetween(start: number, end: number) {
  return (payload?: number) => start <= (payload || 0) && (payload || 0) <= end;
}

export function isEmpty(payload: unknown): boolean {
  return (
    payload === null ||
    payload === undefined ||
    (typeof payload === 'string' && payload.trim().length === 0) ||
    (Array.isArray(payload) && payload.length === 0) ||
    (typeof payload === 'object' &&
      !Array.isArray(payload) &&
      Object.keys(payload).length === 0)
  );
}

export function hasOnlyEmptyStrings(obj: object): boolean {
  return Object.values(obj).every((value) => {
    if (typeof value === 'object' && value !== null) {
      return hasOnlyEmptyStrings(value);
    }
    return value === '';
  });
}
