<script setup lang="ts">
import IconGear from '@/assets/icons/icon-gear.vue';
import AppDrawerLink from '@/components/app/AppDrawerLink/AppDrawerLink.vue';
import { useI18n } from 'vue-i18n';

interface Props {
  mini: boolean;
}

defineProps<Props>();

const { t } = useI18n();
</script>

<template>
  <AppDrawerLink
    to="/organization-settings/details"
    :mini="mini"
    :label="t('nav.organizationSettings')"
  >
    <template #leading>
      <IconGear />
    </template>
  </AppDrawerLink>
</template>
