import { ApiResponse } from '@/shared/types/generic';
import axios from '@/plugins/http/axios';
import {
  EditOrganizationRequest,
  NewOrganizationRequest,
  Organization,
  IOrganizationDetails,
  SalesChannels,
} from '@/shared/types/organizations';
import { SiteLocation } from '@/shared/types/sites';

export default {
  getOrganizations(): Promise<ApiResponse<{ organizations: Organization[] }>> {
    return axios.get('/organizations');
  },

  createOrganization(newOrganization: NewOrganizationRequest): Promise<void> {
    return axios.post('/organizations', newOrganization);
  },

  editOrganization(
    reference: Organization['reference'],
    newOrganization: EditOrganizationRequest,
  ): Promise<void> {
    return axios.patch(`/organizations/${reference}`, newOrganization);
  },

  deleteOrganization(reference: Organization['reference']): Promise<void> {
    return axios.delete(`/organizations/${reference}`);
  },

  getSalesChannels(): Promise<ApiResponse<{ salesChannels: SalesChannels[] }>> {
    return axios.get('/sales-channels');
  },

  getLocations(city: string): Promise<{ results: SiteLocation[] }> {
    return axios.get(`/locations?cityName=${city}`);
  },

  getTimezone(
    lat: number,
    long: number,
  ): Promise<{ utcOffset: string; ianaTimezone: string }> {
    return axios.get(`locations/timezone?latitude=${lat}&longitude=${long}`);
  },

  getOrganizationDetails(
    reference: string,
  ): Promise<ApiResponse<{ organizations: IOrganizationDetails[] }>> {
    return axios.get(`/organizations/${reference}`);
  },
};
