import { defineStore } from 'pinia';
import profileService from '@/shared/services/profileService/profileService';
import { AdminProfile, PartialAdminProfile } from '@/shared/types/profile';

interface State {
  userProfile?: AdminProfile;
}

export default defineStore({
  id: 'profile',

  state: (): State => ({
    userProfile: undefined,
  }),

  actions: {
    resetStore() {
      this.userProfile = undefined;
    },

    async loadProfile() {
      try {
        this.userProfile = await profileService.getProfile();
      } catch (e) {
        this.userProfile = undefined;
      }
    },

    async updateProfile(profile: PartialAdminProfile) {
      await profileService.patchProfile(profile);

      await this.loadProfile();
    },
  },
});
