enum Routes {
  ROOT = '/',
  ICONS = '/icons',
  HOME = '/home',
  LOGIN = '/login',
  FIRST_LOGIN = '/first-login',
  PARTNERS = '/partners',
  PARTNERS_CLIENTS = '/partners/clients',
  PARTNERS_ADD = '/partners/add',
  DELETE_ACCOUNT = '/account-settings/security/delete-account',
  SECURITY_CHANGE_PASSWORD = '/account-settings/security/change-password',
  ADMINS = '/admins',
  ADMINS_ADD = '/admins/add',
  ACCOUNT_SETTINGS = '/account-settings',
}

export default Routes;
