import axios from '@/plugins/http/axios';
import { ApiResponse } from '@/shared/types/generic';
import { Feature } from '@/shared/types/feature';

export default {
  getAllFeatures(): Promise<ApiResponse<{ features: Feature[] }>> {
    return axios.get('/features');
  },

  updateFeaturesStatus(
    organizationReference: string,
    features: Feature[],
  ): Promise<void> {
    return axios.patch(
      `/features/status/organizations/${organizationReference}`,
      {
        updatedStatuses: features,
      },
    );
  },

  getFeaturesByOrganizationReference(
    organizationReference: string,
  ): Promise<ApiResponse<{ features: Feature[] }>> {
    return axios.get(`/features/organizations/${organizationReference}`);
  },
};
