import axios from '@/plugins/http/axios';
import { AdminProfile, PartialAdminProfile } from '@/shared/types/profile';

export default {
  getProfile(): Promise<AdminProfile> {
    return axios.get('/profiles/admin');
  },

  patchProfile(profile: PartialAdminProfile): Promise<AdminProfile> {
    return axios.patch('/profiles/admin', profile);
  },
};
