import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppLayoutDrawer = _resolveComponent("AppLayoutDrawer")!
  const _component_q_page_container = _resolveComponent("q-page-container")!
  const _component_q_layout = _resolveComponent("q-layout")!

  return (_openBlock(), _createBlock(_component_q_layout, { view: "lHh lpR fFf" }, {
    default: _withCtx(() => [
      _createVNode(_component_AppLayoutDrawer, {
        modelValue: _ctx.isDrawerOpen,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isDrawerOpen) = $event)),
        mini: _ctx.miniState
      }, null, 8, ["modelValue", "mini"]),
      _createVNode(_component_q_page_container, { class: "app-layout-page__content" }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _: 3
      })
    ]),
    _: 3
  }))
}