import { defineStore } from 'pinia';
import {
  EditOrganizationRequest,
  NewOrganizationRequest,
  Organization,
  IOrganizationDetails,
  SalesChannels,
} from '@/shared/types/organizations';
import organizationsService from '@/shared/services/organizationsService/organizationsService';
import rolesService from '@/shared/services/rolesService/rolesService';
import { RoleTypeStaticReference } from '@vaibe-github-enterprise/kd-kse-fe-commons';
import featureService from '@/shared/services/featureService/featureService';
import { ResponseError } from '@/shared/types/utils';
import notify from '@/shared/helpers/notify/notify';
import i18n from '@/plugins/i18n';
import { computed, ref } from 'vue';

export default defineStore('organizations', () => {
  const organizations = ref<Organization[]>([]);

  const organizationDetails = ref<IOrganizationDetails | undefined>(undefined);

  const salesChannels = ref<SalesChannels[]>([]);

  const mapperByReference = computed<Record<string, Organization>>(() =>
    organizations.value.reduce((acc, organization) => {
      acc[organization.reference] = organization;

      return acc;
    }, {} as Record<string, Organization>),
  );

  function $reset(): void {
    organizations.value = [];
    salesChannels.value = [];
    organizationDetails.value = undefined;
  }

  async function loadOrganizations(): Promise<void> {
    try {
      organizations.value = (
        await organizationsService.getOrganizations()
      ).results.organizations;
    } catch (e) {
      organizations.value = [];
    }
  }

  async function createOrganization(
    newOrganization: NewOrganizationRequest,
  ): Promise<void> {
    return organizationsService.createOrganization(newOrganization);
  }

  async function editOrganization(
    organizationReference: Organization['reference'],
    newOrganization: EditOrganizationRequest,
  ): Promise<void> {
    await organizationsService.editOrganization(
      organizationReference,
      newOrganization,
    );

    await loadOrganizations();
  }

  async function deleteOrganization(
    organizationReference: Organization['reference'],
  ): Promise<void> {
    return organizationsService.deleteOrganization(organizationReference);
  }

  async function loadSalesChannels(): Promise<void> {
    try {
      salesChannels.value = (
        await organizationsService.getSalesChannels()
      ).results.salesChannels;
    } catch (e) {
      salesChannels.value = [];
    }
  }

  async function loadDetails(
    organizationReference: Organization['reference'],
  ): Promise<Organization> {
    const orgIndex = organizations.value.findIndex(
      (organization) => organization.reference === organizationReference,
    );

    const promises: [
      ReturnType<typeof featureService.getFeaturesByOrganizationReference>,
      ReturnType<typeof rolesService.getDefaultAndCustomRoles>,
    ] = [
      featureService.getFeaturesByOrganizationReference(
        organizations.value[orgIndex].reference,
      ),
      rolesService.getDefaultAndCustomRoles(
        RoleTypeStaticReference.MANAGER,
        organizations.value[orgIndex].reference,
      ),
    ];

    const [featuresByReferenceResponse, defaultAndCustomRolesResponse] =
      await Promise.all(promises);

    organizations.value[orgIndex] = {
      ...organizations.value[orgIndex],
      features: featuresByReferenceResponse.results.features,
      roles: defaultAndCustomRolesResponse,
    };

    return organizations.value[orgIndex];
  }

  async function loadOrganizationDetails(reference: string): Promise<void> {
    try {
      const response = await organizationsService.getOrganizationDetails(
        reference,
      );

      organizationDetails.value = response.results.organizations.at(0);
    } catch (error) {
      if (error instanceof ResponseError) {
        notify(i18n.global.t(`errors.reason.${error.reason.code}`), 'danger');
      }
    }
  }

  return {
    organizations,
    salesChannels,
    mapperByReference,
    organizationDetails,
    $reset,
    loadDetails,
    editOrganization,
    loadOrganizations,
    loadSalesChannels,
    deleteOrganization,
    createOrganization,
    loadOrganizationDetails,
  };
});
