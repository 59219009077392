<script setup lang="ts">
import { computed, watch } from 'vue';
import AppLayout from '@/components/layouts/AppLayout/AppLayout.vue';
import {
  HttpStatus,
  Locale,
  RouteAuthenticationRequirement,
} from '@/shared/types/generic';
import useUserStore from '@/store/user/useUserStore';
import useTokenStore from '@/store/token/useTokenStore';
import { isEmpty } from '@/shared/helpers/validators/validators';
import { updateCommonsLocale } from '@vaibe-github-enterprise/kd-kse-fe-commons';
import { useRoute, useRouter } from 'vue-router';
import useProfileStore from '@/store/profile/useProfileStore';
import useOrganizationsStore from './store/organizations/useOrganizationsStore';
import useErrorStore from './store/error/useErrorStore';
import useDynamicTitle from './composables/useDynamicTitle/useDynamicTitle';
import i18n from './plugins/i18n';
import { BaseLoaderList } from './plugins/commons';

const { updateTitle } = useDynamicTitle();

const profileStore = useProfileStore();
const tokenStore = useTokenStore();
const userStore = useUserStore();
const organizationsStore = useOrganizationsStore();
const errorStore = useErrorStore();

const router = useRouter();
const route = useRoute();

const requiresAuthentication = computed<boolean>(() => {
  return route.matched.some(
    (match) =>
      match.meta.authenticationRequirement ===
      RouteAuthenticationRequirement.AUTHENTICATED,
  );
});

watch(
  () => tokenStore.token,
  (newToken?: string) => {
    if (isEmpty(newToken)) {
      userStore.resetStore();

      profileStore.resetStore();

      organizationsStore.$reset();

      i18n.global.locale.value = process.env.VUE_APP_I18N_LOCALE || Locale.US;
      updateCommonsLocale(process.env.VUE_APP_I18N_LOCALE || Locale.US);
    }
  },
  { immediate: true },
);

watch(
  () => errorStore.errorCode,
  (newErrorCode?: HttpStatus) => {
    switch (newErrorCode) {
      case HttpStatus.FORBIDDEN:
      case HttpStatus.INACTIVE_USER:
      case HttpStatus.UNAUTHORIZED_USER:
        tokenStore.setToken('', '', '');
        router.push(`/login`);
        break;
      case HttpStatus.SERVER_MAINTENANCE:
      case HttpStatus.NO_CONNECTION:
        router.push({
          path: `/${newErrorCode}`,
          query: { bypass: 'true' },
        });
        break;
      default:
        break;
    }
  },
  { immediate: true },
);

watch(
  () => profileStore.userProfile?.userConfig.language,
  (newUserProfileLanguage) => {
    i18n.global.locale.value = newUserProfileLanguage || Locale.US;
    updateCommonsLocale(newUserProfileLanguage || Locale.US);
  },
  { immediate: true },
);

watch(
  () => i18n.global.locale.value,
  () => {
    updateTitle(router.currentRoute.value.name);
  },
  { immediate: true },
);

watch(
  () => route.matched,
  (newVal) => {
    const routes = newVal.filter((match) => {
      return !match.meta.firstLevel && match.name;
    });

    const routeName = String(routes[routes.length - 1].name);

    updateTitle(routeName);
  },
  { immediate: true },
);
</script>
<template>
  <AppLayout v-if="requiresAuthentication">
    <router-view />
    <BaseLoaderList />
  </AppLayout>
  <router-view v-else />
</template>
