import { DrawerLink } from '@/shared/types/components';
import Routes from '@/shared/types/routes';
import { shallowRef } from 'vue';
import links from '.';

const drawerLinks = shallowRef<DrawerLink[]>([]);

export default function useDrawerLinks() {
  function updateDrawerNavigationLinks(
    isPermissionActive: (permissionCode: string) => boolean,
  ) {
    drawerLinks.value = links.filter((link: DrawerLink) => {
      if (link.permissions === undefined) {
        return true;
      }

      return link.permissions.some((permission) =>
        isPermissionActive(permission),
      );
    });
  }

  function getNextAllowedDrawerLink(): string {
    if (drawerLinks.value.length === 0) {
      return Routes.ACCOUNT_SETTINGS;
    }

    return drawerLinks.value[0].to;
  }

  return { drawerLinks, updateDrawerNavigationLinks, getNextAllowedDrawerLink };
}
