import { ApiResponse, ResponseMetadata } from '@/shared/types/generic';
import { Feature } from '@/shared/types/feature';
import { getRandomReference } from '@vaibe-github-enterprise/kd-kse-fe-commons';

const generatedNumbers: Set<number> = new Set([]);

const getRandomNumber = (max = 10, min = 0): number => {
  let randomNumber = 0;

  while (generatedNumbers.has(randomNumber)) {
    randomNumber = Math.floor(Math.random() * (max - min)) + min;
  }

  return randomNumber;
};

const getMetadata = (totalItems: number): ResponseMetadata => ({
  lastUpdated: 1595030400000,
  totalItems,
});

export const mockFeature = (): Feature => ({
  featureCode: getRandomReference(),
  status: [false, true][getRandomNumber(1, 0)],
});

export const mockFeatures = (
  length = 4,
): ApiResponse<{ features: Feature[] }> => {
  const metadata = getMetadata(length);

  return {
    metadata,
    results: {
      features: Array.from({ length }, () => mockFeature()),
    },
  };
};

export const mockFeatureCodes = (
  length = 4,
): ApiResponse<{ featureCodes: string[] }> => {
  const metadata = getMetadata(length);

  return {
    metadata,
    results: {
      featureCodes: Array.from({ length }, () => getRandomNumber().toString()),
    },
  };
};
